<script setup lang="ts">
import useKeyboardGlobalEvent from '~/composables/useKeyboardGlobalEvent';

const modalStore = useModalStore();

const props = defineProps({
  food: {
    type: Object,
    default: () => {},
  },
  quantity: Number,
  portions: {
    type: Array,
    default: () => [],
  },
  onConfirm: Function,
  default: () => {},
});

const inputPortionRef = ref();
const selectedPortionCount = ref({});
const forceEditPortionCount = ref(props.quantity ?? 0);

const totalPortionQuantity = computed(() => {
  return Object.keys(selectedPortionCount.value).reduce((acc, key) => {
    const quantity =
      selectedPortionCount.value[key].portion.overrideQuantity ??
      selectedPortionCount.value[key].portion?.portion?.quantity ??
      selectedPortionCount.value[key].portion?.quantity ??
      0;
    acc += selectedPortionCount.value[key].count * quantity;
    return acc;
  }, 0);
});
watch(
  () => totalPortionQuantity.value,
  (total) => {
    foodSurveyData.quantity = total;
    forceEditPortionCount.value = total;
  },
);

function handlePortionClick(portion) {
  if (!selectedPortionCount.value[portion.id]) {
    selectedPortionCount.value[portion.id] = {
      portion,
      count: 1,
    };
  } else {
    selectedPortionCount.value[portion.id].count += 1;
  }
}

const foodSurveyData = reactive({
  quantity: 0,
});

async function handleAddFoodToSurvey() {
  props.onConfirm(forceEditPortionCount.value || foodSurveyData.quantity);
  await modalStore.closeModal();
}

function getLabel(portion) {
  return portion.overrideLabel ?? portion.portion?.label ?? portion.label;
}

function getQuantity(portion) {
  if (!portion) {
    return 0;
  }
  return (
    portion.overrideQuantity ?? portion.portion?.quantity ?? portion.quantity
  );
}

function getPortionCount(portion) {
  return selectedPortionCount.value[portion.id]?.count ?? 0;
}

function getIcon(portion) {
  const icons = {
    cuillère: 'fa6-solid:spoon',
    tranche: 'fa6-solid:bread-slice',
    part: 'fa6-solid:pizza-slice',
    verre: 'lucide:glass-water',
    pincée: 'tabler:salt',
    carré: 'iconoir:chocolate',
    litre: 'tabler:bottle',
    tasse: 'mingcute:teacup-line',
    canette: 'hugeicons:soda-can',
    cuisse: 'mdi:food-drumstick',
    oeuf: 'fluent:food-egg-24-filled',
    filet: 'mdi:food-steak',
    assiette: 'ph:bowl-food',
    poké: 'ph:bowl-food',
    poignée: 'icon-park-outline:hold-seeds',
    pot: 'hugeicons:yogurt',
    saucisse: 'tdesign:sausage',
    cocktail: 'mdi:glass-cocktail',
  };

  const portionLabel = getLabel(portion);

  const key = Object.keys(icons).find((k) =>
    portionLabel?.toLowerCase().includes(k),
  );
  return icons[key] ?? 'material-symbols:scale';
}

onMounted(() => {
  setTimeout(() => {
    inputPortionRef.value?.focus();
  }, 100);
});

useKeyboardGlobalEvent('Enter', () => {
  inputPortionRef.value?.blur();
  setTimeout(() => {
    handleAddFoodToSurvey();
  }, 200);
});
</script>

<template>
  <BaseModal
    :title="`Préciser la quantité pour ${getLocaleTranslation(props.food.translations)?.label}`"
    :width="900"
  >
    <div class="portions flex justify-center gap-2 p-4">
      <div
        class="portion p-4 border-2 rounded border-gray-400 cursor-pointer flex items-center"
        v-for="p in portions"
        :key="p.id"
        @click="handlePortionClick(p)"
      >
        <BaseIcon :icon="getIcon(p)" size="40"></BaseIcon>
        <p class="font-bold">
          <span class="text-xl">{{ getPortionCount(p) }} </span
          >{{ `x ${getLabel(p)}` }}
        </p>
        <p class="text-sm">({{ getQuantity(p) }} {{ p.unit.label }})</p>
      </div>
    </div>
    <div class="mt-2 text-right">
      <h2 v-show="totalPortionQuantity">Total: {{ totalPortionQuantity }}g</h2>
    </div>

    <BaseInput
      ref="inputPortionRef"
      v-model="forceEditPortionCount"
      type="number"
    ></BaseInput>
    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleAddFoodToSurvey" />
    </template>
  </BaseModal>
</template>

<style scoped></style>
